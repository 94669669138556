import React from 'react'

import { IdsIcon, IdsLoading } from '@ids/react'
import { If } from '@itau-loans-www/shopping/src/components'

import './loader.scss'

const Loader = ({ activedText }) => {
  return (
    <div className="loader">
      <If
        condition={activedText === 3}
        renderIf={
          <div className="loader__complete">
            <IdsIcon size="XL" contrast="onBrand">
              check
            </IdsIcon>
          </div>
        }
        renderElse={
          <IdsLoading contrast="onBrand" size="XL">
            <IdsIcon size="XL">busca_outline</IdsIcon>
          </IdsLoading>
        }
      />
    </div>
  )
}

export default Loader
