import { useEffect, useState } from 'react'

import { useLead, useMapper } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from './services'

export const useSearchingOffer = () => {
  const { changeOffers } = useLead()

  const { mapHero, mapOthers, mapBlocked } = useMapper()

  const [activedText, setActivedtext] = useState(0)

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'proposta/pesquisando-credito',
      customLayer: {
        description: 'searching-credit-loading-inicio'
      }
    })

    Promise.all([services.getSimulationSummary()])
      .then(([res]) => {
        const mappedHero = mapHero(res)
        const mappedOthers = mapOthers(res)
        const mappedBlocked = mapBlocked(res)

        const desiredLoanValue = res?.desired_loan_value
        const leadName = res?.lead_name
        const errorTitle = res?.error_title

        changeOffers({
          leadName,
          errorTitle,
          desiredLoanValue,
          hero: mappedHero,
          others: mappedOthers,
          blocked: mappedBlocked
        })
      })
      .then(() => {
        changeOffers({ isSearching: false })
        setTimeout(() => {
          analytics.track('pageLoad', {
            currentPage: 'proposta/pesquisando-credito',
            customLayer: {
              description: 'searching-credit-loading-fim'
            }
          })
          goTo('/proposta/ofertas')
        }, 1500)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeOffers])

  useEffect(() => {
    const handlePopState = (e) => {
      history.forward(1)
      e.preventDefault()

      goTo('/')
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  return {
    activedText,
    setActivedtext
  }
}
