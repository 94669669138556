import Layout from '@itau-loans-www/shopping/src/layouts/Application'

import Img from 'components/Img'

import Loader from './components/Loader'
import TextSlider from './components/TextSlider'
import { SLIDERTEXTS } from './constants'
import { useSearchingOffer } from './hooks'
import './searching-credit.scss'

const SearchingCredit = () => {
  const { activedText, setActivedtext } = useSearchingOffer()

  return (
    <Layout className="page -searching-credit">
      <div className="searching-credit__container">
        <Img
          className="img"
          file="loading-desktop"
          alt="Ilustração onde temos uma casa, um carro, uma moeda, uma caixa de mensagem, uma sacola e um símbolo de check"
        />
        <div className="content">
          <TextSlider
            activedText={activedText}
            setActivedtext={setActivedtext}
            textsArray={SLIDERTEXTS}
          />

          <div className="loader-content">
            <Loader activedText={activedText} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SearchingCredit
