import { useEffect, useState } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'

import './text-slider.scss'

const TextSlider = ({ textsArray, activedText, setActivedtext }) => {
  const [hasFinished, setHasFinished] = useState(false)
  const textsLength = textsArray.length
  const { offers, changeOffers } = useLead()
  const notFinishedAndStillSearching =
    !hasFinished && offers.isSearching !== undefined && !offers.isSearching

  const isFirstElement = activedText % 2 === 0
  const isSecondElement = activedText % 2 !== 0

  const activeText = textsArray[activedText]
  const lastText = textsArray[textsLength - 1]

  useEffect(() => {
    const sliderTimer = setInterval(() => {
      setActivedtext((counter) => {
        return counter < textsLength - 2 ? counter + 1 : counter
      })
    }, 5500)

    return () => {
      setInterval(sliderTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (notFinishedAndStillSearching) {
      setActivedtext(textsLength - 1)
      changeOffers({ isSearching: true })
      setHasFinished(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers.isSearching])

  return (
    <div className="text-slider">
      <h3
        className={`slidertext ${isFirstElement ? '-primary' : '-secondary'}`}
      >
        {isFirstElement ? activeText : lastText}
      </h3>

      <h3
        className={`slidertext ${isSecondElement ? '-primary' : '-secondary'}`}
      >
        {isSecondElement ? activeText : lastText}
      </h3>
    </div>
  )
}

export default TextSlider
